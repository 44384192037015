import * as React from "react";
import Head from "next/head";
import AppsFlyer from "../../../components/common/AppsFlyer";
import BannerNewComponentV2 from "../../../components/common/BannerNew/BannerNewComponentV2";
import FooterComponentV2 from "../../../components/common/Footer/FooterComponentV2";
import HeaderComponentV2 from "../../../components/common/Header/HeaderComponentV2";
import OfferPopupComponent from "../../../components/common/OfferPopup/OfferPopupComponent";
import SideDrawerComponent from "../../../components/common/SideDrawer/SideDrawerComponent";
import ToastComponent from "../../../components/common/Toast/ToastComponent";
import { IGetStaticPageDataReturnProps } from "../../../lib/types/Misc.type";
import { ICommonData } from "../../../lib/types/common";
import {
  IToastComponentProps,
  IFloatingDownloadButtonComponentProps,
  ISideDrawerComponentProps,
  IHeaderComponentProps,
  IBannerNewComponentProps,
  IBannerDownloadButtonComponentProps,
  ICommonWithdrawalPartnerComponentProps,
  IWinzoBannerBottomComponentHomePageProps,
  IWinzoTaglineProps,
  IPopularGamesComponentProps,
  IWinzoWinnerComponentProps,
  IUserRatingComponentProps,
  IHowToInstallAppComponentProps,
  IWinzoUSPComponentProps,
  IMemberOfComponentProps,
  IWinzoStoreOfferComponentProps,
  IWithdrawalPartnersComponentProps,
  IWinzoVideoComponentProps,
  IDownloadAppComponentProps,
  IPageFAQComponentProps,
  IFooterComponentProps,
  IFooterLinksSectionProps,
} from "../../../lib/types/components";
import { IHomePageLanguageData } from "../../../lib/types/home";
import { IHomePageUSLanguageData } from "../../../lib/types/home.us";
import {
  GET_APP_REF_URL,
  GET_APP_PLAY_URL,
  HOME_PAGE_URL,
  GET_APP_KWAI_URL,
  GET_APP_TIKTOK_URL,
  FIXED_DOWNLOAD_BUTTON_TYPE,
  VariantA,
  GET_APP_MIGR_URL,
  GET_APP_US_URL,
} from "../../../lib/utils/constants/GlobalConstants";
import { COUNTRY } from "../../../lib/utils/constants/LocaleAndCountryConstants";
import {
  isGetAppPage,
  checkIsGetAppAF,
  getCountryFromLocale,
  isMobile,
} from "../../../lib/utils/constants/PagesConstants";
import { COLORMODE, HOW_TO_INSTALL_FLOW } from "../../../lib/utils/enums/enums";
import { showHowToInstallStory } from "../../../lib/utils/helpers/helper";
import FaqComponent from "../../../components/GermanyDesign/FaqComponent/FaqComponent";
import AboutUsComponent from "../../../components/GermanyDesign/AboutUsComponent/AboutUsComponent";
import HowToInstallComponent from "../../../components/GermanyDesign/HowToInstallComponent/HowToInstallComponent";
import WhyWinzo from "../../../components/GermanyDesign/WhyWinzo/WhyWinzo";
import OurInfluencers from "../../../components/GermanyDesign/OurInfluencers/OurInfluencers";
import TrustedByEveryone from "../../../components/GermanyDesign/TrustedByEveryone/TrustedByEveryone";
import PaymentPartnerComponent from "../../../components/GermanyDesign/PaymentPartnerComponent/PaymentPartnerComponent";
import OurGames from "../../../components/GermanyDesign/OurGames/OurGames";
import FloatingDownloadButtonWithStory from "../../../components/GermanyDesign/FloatingDownloadButton/FloatingDownloadButtonWithStory";
import FloatingDownloadButtonWithVideo from "../../../components/GermanyDesign/FloatingDownloadButton/FloatingDownloadButtonWithVideo";

function IndexPageGermany(
  props: IGetStaticPageDataReturnProps<IHomePageLanguageData>
) {
  const locale = props.apiData.locale;
  const pageName = props.apiData.pageName;
  const howToInstallVariant = props.apiData.howToInstallVariant;
  const showOfferPopup: boolean = props.apiData.showOfferPopup!;
  // All get-app pages boolean
  const isGetAppRef: boolean = pageName === GET_APP_REF_URL;
  const isGetApp: boolean = isGetAppPage(pageName);
  const isGetAppAf: boolean = checkIsGetAppAF(pageName);
  const isGetAppPlay: boolean = pageName === GET_APP_PLAY_URL;
  const isGetAppMigr: boolean = pageName === GET_APP_MIGR_URL;
  const isGetAppUs: boolean = pageName === GET_APP_US_URL;
  const isHomePage: boolean = pageName === HOME_PAGE_URL;
  const isGetAppKwai: boolean = pageName === GET_APP_KWAI_URL;
  const isGetAppTiktok: boolean = pageName === GET_APP_TIKTOK_URL;
  // variable containing api data from getStaticProps
  const content: IHomePageLanguageData | IHomePageUSLanguageData =
    props.apiData;
  const commonData: ICommonData = props.apiData.commonData as ICommonData;
  const selectLanguageText = props.apiData.commonData.selectLanguageText;
  //TODO : REMOVE THESE CHECKS AFTER text3 AND text4 ARE MOVED TO COMMON.
  if (commonData.text3) {
    content.bannerHome.text3 = commonData.text3;
  }
  if (commonData.text4) {
    content.bannerHome.text4 = commonData.text4;
  }

  // All components' props' values assigned
  const toastCompProps: IToastComponentProps = {
    internetError: commonData?.toastComponent.internetError,
  };

  const floatingDownloadBtnCompProps: IFloatingDownloadButtonComponentProps = {
    type: FIXED_DOWNLOAD_BUTTON_TYPE,
    blinkTextDownload: commonData.blinkTextDownloadButton,
    blinkTextDownload2: commonData.blinkTextDownloadButton2,
    fixedDownloadButtonData: commonData.fixedDownloadButton,
    locale: locale,
    //TODO
    howToInstallVideo:
      content?.howToInstall?.howToInstallVideo,
    howToInstallStory: commonData?.howToInstallStory,
    howToInstallThumbnail:
      content?.howToInstall?.howToInstallThumbnail,
    downloadingAnimation: commonData.downloadingAnimation,
    isHomePage: isHomePage,
    pageName,
    // setAppsFlyerURL
  };

  const sideDrawerCompProps: ISideDrawerComponentProps = {
    navigationDataLinks: commonData.navigationDataLinks,
    navigationDataLinksV2: commonData.navigationDataLinksV2,
  };
  const headerCompProps: IHeaderComponentProps = {
    specialData: commonData.specialData,
    hideHamburger: content.hideHamburger,
    fixedDownloadButtonData: commonData.fixedDownloadButton,
    languageToggleData: props.apiData.languageToggleData,
    locale: locale,
    countrySwitcherText: commonData.multiRegional?.countrySwitcherText,
    pageName,
    selectLanguageText,
  };
  const bannerNewCompProps: IBannerNewComponentProps = {
    bannerHomeData: content.bannerHome,
    smsSentModalData: commonData.smsSentModal,
    locale: locale,
    smsVariablesTemplate: commonData.smsVariablesTemplate,
    mobileNumber: commonData.mobileNumber,
    consent: commonData.consent,
    disableAppDownload: commonData.fixedDownloadButton.disabled || false,
    pageName,
    bannerMobilePlaystore: commonData.bannerMobilePlaystore,
    topMargin: true,
    qrData: commonData.qrData,
  };

  const winzoWinnerCompProps: IWinzoWinnerComponentProps = {
    winzoWinnerData: commonData.winzoWinner,
  };
  const userRatingCompProps: IUserRatingComponentProps = {
    customerReviewData: commonData.customerReview,
    winzoWinnerCompProps: winzoWinnerCompProps,
  };

  const pageFAQCompProps: IPageFAQComponentProps = {
    pageFAQData: content.pageFAQ,
    showFaqSchema: false,
  };
  const footerCompProps: IFooterComponentProps = {
    // downloadLink: apkUrl,
    footerData: commonData.footer,
    membersData: commonData.members,
    withdrawalPartnerData: commonData.withdrawalPartner,
    pageName: pageName,
    colorMode: COLORMODE.DARK,
  };

  const getHowToInstallStoryComponent = () => {
    return (
      <FloatingDownloadButtonWithStory {...floatingDownloadBtnCompProps} />
    );
  };

  const getHowToInstallVideoComponent = () => {
    return (
      <FloatingDownloadButtonWithVideo {...floatingDownloadBtnCompProps} />
    );
  };

  return (
    <>
      <Head>
        {(isGetAppRef || isGetApp || isGetAppAf || isGetAppPlay || isGetAppMigr || isGetAppUs) && (
          <meta name="robots" content="noindex, nofollow" />
        )}
      </Head>
      <AppsFlyer
        pageName={pageName}
        variant={props.apiData?.variant}
        disableOrganicUrl={pageName !== HOME_PAGE_URL}
        extraParams={{
          af_siteid:
            howToInstallVariant === VariantA
              ? HOW_TO_INSTALL_FLOW.VIDEO
              : HOW_TO_INSTALL_FLOW.STORY,
        }}
      />
      {/*{isGetApp && getQueryStrings().utm === UTM_TRUECALLER && (*/}
      {/*    <img*/}
      {/*        height={1}*/}
      {/*        width={1}*/}
      {/*        style={{display: "none", position: "absolute"}}*/}
      {/*        src={trueCallerPixelImage}*/}
      {/*        alt="true caller"*/}
      {/*    />*/}
      {/*)}*/}
      {showOfferPopup && <OfferPopupComponent />}
      <ToastComponent {...toastCompProps} />

      {isHomePage &&
      [COUNTRY.INDIA, COUNTRY.BRAZIL, COUNTRY.GERMANY].includes(
        getCountryFromLocale(locale)
      ) ? (
        <>
          {howToInstallVariant === VariantA
            ? getHowToInstallVideoComponent()
            : getHowToInstallStoryComponent()}
        </>
      ) : (
        getHowToInstallVideoComponent()
      )}

      <SideDrawerComponent {...sideDrawerCompProps} />
      <main>
        <HeaderComponentV2
          {...headerCompProps}
          storyFlow={
            howToInstallVariant !== VariantA &&
            showHowToInstallStory(commonData, content)
          }
        />
        <BannerNewComponentV2 {...bannerNewCompProps} />

        {commonData.bannerBottom && (
          <PaymentPartnerComponent {...commonData.bannerBottom} />
        )}
        <WhyWinzo winzoUSPData={content.winzoUSP} />

        <TrustedByEveryone {...userRatingCompProps} />
        <OurInfluencers {...content.ourInfluencers} />
        <OurGames {...content.popularGamesV2} />
        <HowToInstallComponent howToInstallData={commonData.howToInstall} />
        <AboutUsComponent aboutUsData={content.aboutUs} />
        <div>{isGetAppAf ? <></> : <FaqComponent {...pageFAQCompProps} />}</div>
        {/* <div>
          {isGetAppAf || isGetAppRef ? (
            <></>
          ) : (
            <FooterLinksSectionComponentV2 {...footerLinksSection} />
          )}
        </div> */}
        <FooterComponentV2 {...footerCompProps} />
      </main>
    </>
  );
}

export default IndexPageGermany;
