import { getWebsiteEnvironment } from "../utils/constants/EnvironmentConstants";

export default function SiteNavigationSchema() {
    return (
        <>
            <script
                type="application/ld+json"
                dangerouslySetInnerHTML={{
                    __html: `{
                        "@context": "http://schema.org",
                        "@type": "ItemList",
                        "itemListElement": [
                          {
                            "@type": "SiteNavigationElement",
                            "name": "Ludo",
                            "url": "https://${getWebsiteEnvironment().baseUrl}/all-games/ludo"
                          },
                          {
                            "@type": "SiteNavigationElement",
                            "name": "Solitaire",
                            "url": "https://${getWebsiteEnvironment().baseUrl}/all-games/solitaire-game"
                          },
                          {
                            "@type": "SiteNavigationElement",
                            "name": "Candy Match",
                            "url": "https://${getWebsiteEnvironment().baseUrl}/all-games/candy-match"
                          },
                          {
                            "@type": "SiteNavigationElement",
                            "name": "Chess",
                            "url": "https://${getWebsiteEnvironment().baseUrl}/all-games/chess"
                          },
                          {
                            "@type": "SiteNavigationElement",
                            "name": "Rummy",
                            "url": "https://${getWebsiteEnvironment().baseUrl}/all-games/rummy"
                          },
                          {
                            "@type": "SiteNavigationElement",
                            "name": "WinZO Blog",
                            "url": "https://${getWebsiteEnvironment().baseUrl}/blog/"
                          }
                        ]
                    }`,
                }}
            />
        </>
    )
}